/*@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Damion&family=Roboto:wght@300;400;500;700;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    /*background-image: url("https://wallpapercrafter.com/desktop/13142-hill-night-horizon-dark-minimalism-4k.jpg");*/
    background-image: url("https://images.unsplash.com/photo-1596750462153-5b6217be4bfa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1284&q=80");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: rgba(26, 26, 26, 0.93);
}

.bg-break-point {
    background-image: url("https://images.unsplash.com/photo-1540198163009-7afda7da2945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1527&q=80");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
#contact {
    background-image: url("https://images.unsplash.com/photo-1540198163009-7afda7da2945?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1527&q=80");
    background-position: center;
    background-attachment: fixed;
    background-size: cover;
    background-repeat: no-repeat;
}
@media screen and (max-width: 768px) {
    body {
        background-image: none;
        background-color: #0d131f;
    }
    .bg-break-point {
        display: none;
    }
    #contact {
        background-image: none;
        background-color: rgb(44, 44, 44);
    }
}
.font-footer {
    font-family: 'Damion', cursive;
}
a {
    position: relative;
    text-decoration: none;
    cursor: pointer;
}
a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: rgb(192 132 252);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.2s ease;
}
span:hover a::before {
    transform: scaleX(1);
}
.a {
    position: relative;
    text-decoration: none;
    cursor: pointer;
}
.a::before {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: -1px;
    left: 0;
    background-color: rgb(192 132 252);
    transform: scaleX(0);
    transform-origin: top left;
    transition: transform 0.2s ease;
}
li:hover .a::before {
    transform: scaleX(1);
}
li {
    cursor: pointer;
}

button:hover svg {
    fill: rgb(192 132 252);
}

svg {
    opacity: 0.75;
    transition: 0.2s;
}
svg:hover {
    fill: rgb(192 132 252);
    opacity: 1;
}

